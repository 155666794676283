
import { Component, Vue } from "vue-property-decorator";
import { RightsService, WechatService } from "src/services";
import { Toast } from "vant";
import dayjs from "dayjs";
import { tools } from "src/utils";
import globalConfig from "src/config/index";

@Component
export default class GoodsDetail extends Vue {
  /**
   * 是否显示激活框
   * @private
   * @returns string
   */
  private showActivate: boolean = false;

  /**
   * 加载中
   * @private
   * @returns
   */
  private loading: boolean = false;

  /**
   * 是否显示确认退款弹框
   * @private
   * @returns
   */
  private showRefundDialog: boolean = false;

  /**
   * 数据模型
   * @private
   * @returns any
   */
  private dataModel: any = {
    id: 0,
    status: 0,
    statusStr: "",
    isOvertimeUnPay: true,
    payDeadlineTime: "",
    payDeadlineMilliseconds: 0,
    sourceKind: 2,
    sourceKindStr: "",
    orderNo: "",
    price: 0,
    enterpriseAmout: 0,
    marketPrice: 0,
    actualAmount: 0,
    createdTime: "",
    cancelTime: "",
    payedTime: "",
    refundedTime: "",
    completedTime: "",
    backgroundColor: "",
    activedLimitTime: "",
    activedLimitTimeStr: "",
    activedEffTime: "",
    activedEffTimeStr: "",
    rightsId: 0,
    rightsName: "",
    rightsDesc: "",
    rightsProducts: [],
    rightsUsedRecords: [],
  };

  /**
   * 商品订单id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 跳转商品详情
   * @private
   * @returns string
   */
  private goRightsDetail(): void {
    this.$router.push({ name: "rights-detail", query: { id: this.dataModel.id } });
  }

  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
  protected created() {
    this.getMedicalOrderDetail();
  }

  /**
   * 获取订单详情
   * @protected
   * @returns void
   */
  protected async getMedicalOrderDetail() {
    try {
      let { content: result } = await RightsService.instance.getClient(this.id);

      if (result.data) {
        this.dataModel = result.data;
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 服务订单退款
   * @protected
   * @returns void
   */
  protected async refundMedicalOrder() {
    try {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let res: any = await RightsService.instance.clientCancel(this.id);
      if (res.code == 1000) {
        this.showRefundDialog = false;
        this.loading = false;
        Toast("申请成功！");
      }
      this.getMedicalOrderDetail();
      this.showRefundDialog = false;
    } catch (err) {
      this.showRefundDialog = false;
      this.loading = false;
      // Toast(JSON.stringify(err));
    }
  }

  /**
   * 预约服务订单
   * @protected
   * @returns void
   */
  protected onAppointment() {
    this.$router.push({ name: "medical-order-appointment", query: { id: this.id } });
  }

  /**
   * 复制
   * @param str
   */
  private async copyOrderNo(str): Promise<void> {
    let save = function (e) {
      e.clipboardData.setData("text/plain", str);
      e.preventDefault();
    };
    let input = document.createElement("input");
    input.value = str;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length), document.execCommand("Copy");
    document.body.removeChild(input);
    Toast({
      message: "复制成功",
      duration: 1000,
    });
  }

  /**
   * 激活
   * @private
   * @returns void
   */
  private async onActive(): Promise<void> {
    try {
      await RightsService.instance.clientActive(this.dataModel.id);
      Toast("激活成功");
      this.showActivate = false;
      this.getMedicalOrderDetail();
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 跳转地图
   * @private
   * @returns void
   */
  private async openMap(hospitalOut: any): Promise<any> {
    if (hospitalOut) {
      let lat = hospitalOut.appointOrgLatitude;
      let lng = hospitalOut.appointOrgLongitude;
      let address = hospitalOut.appointOrgAddress;
      let orgName = hospitalOut.appointOrgName;

      if (await tools.isWechat()) {
        let currentUrl = location.href;
        let { content: result } = await WechatService.instance.getWechatJSSDK(currentUrl);
        if (result.data) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.data.appId, // 必填，公众号的唯一标识
            timestamp: result.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
            signature: result.data.signature, // 必填，签名
            jsApiList: ["openLocation"], // 必填，需要使用的JS接口列表
          });

          wx.ready(function () {
            wx.openLocation({
              latitude: parseFloat(lat), // 纬度，范围为-90~90，负数表示南纬
              longitude: parseFloat(lng), // 经度，范围为-180~180，负数表示西经
              scale: 14, // 缩放比例
              name: orgName,
              address: address, // 地图缩放级别,整形值,范围从1~28。默认为最大
              infoUrl: "",
            });
          });
        }
      } else {
        // 腾讯地图
        // let href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};addr:${this.orgDetailInfo.org_name}`;
        // 高德
        let href = `https://uri.amap.com/marker?position=${lng},${lat}&name=${orgName}&coordinate=gaode&callnative=1`;
        window.open(href);
      }
    }
  }
}
