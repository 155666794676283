var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rights-list",class:{ 'page-back-head': !(_vm.employerConfig && _vm.employerConfig.hidTitle) }},[(!(_vm.employerConfig && _vm.employerConfig.hidTitle))?_c('u-page-back',{attrs:{"title":"权益中心","pathName":"personal-center"}}):_vm._e(),_c('van-sticky',{attrs:{"offset-top":_vm.employerConfig && _vm.employerConfig.hidTitle ? 0 : 40}},[_c('van-tabs',{staticClass:"rights-list-tabs",attrs:{"color":"#02B0A7","sticky":""},on:{"change":_vm.onTabChange},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"待激活","name":"2"}}),_c('van-tab',{attrs:{"title":"使用中","name":"6"}}),_c('van-tab',{attrs:{"title":"已失效","name":"0,8"}})],1)],1),_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[(_vm.rightsList && _vm.rightsList.length)?_c('div',{staticClass:"rights-list-warp"},_vm._l((_vm.rightsList),function(item,index){return _c('div',{key:index,staticClass:"panel-content item",style:({
          'background-image': `url(${require('@/assets/img/rights/cards/card-' +
            item.backgroundColor.substring(1) +
            '.png')})`,
          'background-size': 'contain',
        }),on:{"click":function($event){return _vm.onGopage('rights-detail', item.id)}}},[(item.status == 8 || item.status == 0)?_c('div',{staticClass:"lose-content"},[_c('img',{staticClass:"img-status",attrs:{"src":require("@/assets/img/rights/status/lose.png"),"alt":""}})]):_vm._e(),_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.rightsName))]),_c('div',{staticClass:"item-bottom-content hea-row hea-cl"},[(item.status == 2)?[_c('div',{staticClass:"item-time hea-flex-item1"},[_vm._v("本卡激活有效期至"+_vm._s(item.activedLimitTimeStr))]),_c('div',{staticClass:"item-btn hea-c",on:{"click":function($event){$event.stopPropagation();return (() => {
                  _vm.showActivate = true;
                  _vm.checkId = item.id;
                }).apply(null, arguments)}}},[_vm._v(" 激活 ")])]:(item.status == 6)?[_c('div',{staticClass:"item-time hea-flex-item1"},[_vm._v("本卡服务有效期至"+_vm._s(item.activedEffTimeStr))])]:[(item.activedEffTimeStr)?_c('div',{staticClass:"item-time hea-flex-item1"},[_vm._v(" 本卡服务有效期至"+_vm._s(item.activedEffTimeStr)+" ")]):_c('div',{staticClass:"item-time hea-flex-item1"},[_vm._v("本卡激活有效期至"+_vm._s(item.activedLimitTimeStr))])],(item.status == 6)?_c('div',{staticClass:"item-btn hea-c"},[_vm._v("去使用")]):_vm._e()],2)])}),0):_c('div',{staticClass:"no-rights"},[_c('img',{staticClass:"no-rights-icon",attrs:{"src":require("@/assets/img/rights/no-data.png")}}),_c('span',{staticClass:"no-rights-txt"},[_vm._v(" 暂无权益卡哦 ")])])]),_c('van-dialog',{attrs:{"showConfirmButton":false,"width":320},model:{value:(_vm.showActivate),callback:function ($$v) {_vm.showActivate=$$v},expression:"showActivate"}},[_c('div',{staticClass:"activate-dialog"},[_c('div',{staticClass:"title"},[_vm._v("权益卡一经激活不可退款，是否"),_c('br'),_vm._v("继续？")]),_c('div',{staticClass:"operation hea-row"},[_c('button',{staticClass:"hea-c btn-cancel",on:{"click":function($event){_vm.showActivate = false}}},[_vm._v("我再想想")]),_c('button',{staticClass:"hea-c btn-confirm",on:{"click":_vm.onActive}},[_vm._v("激活")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }