
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { Service } from "src/decorator";
import { RightsService } from "src/services";
import dayjs from "dayjs";
import globalConfig from "src/config/index";
import merge from "webpack-merge";

@Component
export default class RightsList extends Vue {
  /**
   * 选中id
   * @private
   * @returns number
   */
  private checkId: any = null;

  /**
   * 是否显示激活框
   * @private
   * @returns string
   */
  private showActivate: boolean = false;

  /**
   * 搜索关键字
   * @private
   * @returns string
   */
  private filters: any = {
    statuses: [2],
  };

  /**
   * 加载
   */
  private loading: boolean = false;

  /**
   * tab选中
   * @private
   * @returns number | string
   */
  private active: any = "6";

  /**
   * 时间处理工具
   * @private
   * @returns dayjs
   */
  private dayjs: any = dayjs;

  /**
   * 订单列表
   * @private
   * @returns Array<any>
   */
  private rightsList: Array<any> = [];

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 获取订单列表
   * @private
   * @returns void
   */
  private async getRightsList(): Promise<void> {
    try {
      let filters = Object.assign(this.filters);
      let { content: result } = await RightsService.instance.clientList(filters);
      if (result.data) {
        this.rightsList = result.data || [];
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    if (this.$route.query && this.$route.query.active) {
      this.filters.statuses = (this.$route.query.active as string).split(",").map((res) => +res);
      this.active = this.$route.query.active + "";
    } else {
      this.filters.statuses = [2];
      this.active = "2";
    }
    this.getRightsList();
  }

  /**
   * 刷新数据
   * @private
   * @returns void
   */
  private onRefresh(): void {
    setTimeout(() => {
      Toast("刷新成功");
      this.loading = false;
    }, 1000);

    this.getRightsList();
  }

  /**
   * 切换
   * @private
   * @returns void
   */
  private onTabChange(data: any): void {
    this.filters.statuses = data.split(",").map((res) => +res);
    this.$router.push({ query: merge({}, { active: data }) });
    this.getRightsList();
  }

  /**
   * 跳转页面
   * @private
   * @returns void
   */
  private onGopage(name: string, id: any): void {
    if (id) {
      this.$router.push({ name, query: { id: id + "" } });
    } else {
      this.$router.push({ name });
    }
  }

  /**
   * 激活
   * @private
   * @returns void
   */
  private async onActive(): Promise<void> {
    try {
      await RightsService.instance.clientActive(this.checkId);
      Toast("激活成功");
      this.showActivate = false;
      this.getRightsList();
      setTimeout(() => {
        this.$router.push({ name: "rights-detail", query: { id: this.checkId + "" } });
      }, 50);
    } catch (err) {
      Toast(err);
    }
  }
}
